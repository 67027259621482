@import url('https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure the body and html take full height and prevent horizontal scrolling */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  /* Hide scrollbar for Chrome, Safari, and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  html {
    scrollbar-width: none;
  }
  
  /* Hide scrollbar for IE & Edge */
  body {
    -ms-overflow-style: none;
  }
  